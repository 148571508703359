<template>
  <VDataTable
    :items="courses"
    :headers="headers"
  >
    <template #item.actions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_CONTENT_STORAGE_COURSE_EDIT,
              query : {
                labels : parseToQuerystring({ labels : item.labels })
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактировать курс</span>
      </VTooltip>
    </template>
  </VDataTable>
</template>

<script>
import qs from 'qs';

const parseToQuerystring = (query, options = {}) => qs.stringify(query, options);

export default {
  name: 'CoursesTable',
  inject: ['Names'],
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Название',
          align: 'left',
          value: 'title',
        },
        {
          text: '',
          align: 'left',
          value: 'actions',
        },
      ],
    };
  },
  methods: {
    parseToQuerystring,
  },
};
</script>
