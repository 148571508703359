<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle class="justify-space-between">
            <span>Поиск курса по меткам</span>
            <VBtn
              :to="{ name : Names.R_CONTENT_STORAGE_COURSE_CREATE }"
              color="primary"
              depressed
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать
            </VBtn>
          </VCardTitle>

          <VCardText>
            <VRow>
              <VCol cols="12">
                <VAutocomplete
                  v-model="labelsSelected"
                  class="mb-4"
                  :items="labelsList"
                  label="Выбрать метки"
                  multiple
                  chips
                  filled
                  deletable-chips
                  hide-details
                  item-text="name"
                  item-value="index"
                  return-object
                  :filter="labelsAutocompleteFilter"
                >
                  <template #item="{ item }">
                    <span><strong>Name</strong>: {{ item.name }}, <strong>value</strong>: {{ item.value }}</span>
                  </template>
                </VAutocomplete>

                <VBtn
                  v-if="labelsSelected.length"
                  depressed
                  color="primary"
                  @click="getCourses"
                >
                  Найти курс
                </VBtn>
              </VCol>
            </VRow>

            <VRow v-if="coursesList.length">
              <VCol cols="12">
                <CoursesTable
                  v-if="coursesList.length"
                  :courses="coursesList"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import omit from 'lodash/omit';
import CoursesTable from '../../../components/contentStorage/courses/CoursesTable.vue';

export default {
  name: 'CourseSearch',
  components: { CoursesTable },
  inject: ['Names'],
  data() {
    return {
      labelsSelected: [],
      labelsList: [],
      coursesList: [],
    };
  },
  created() {
    this.getAllLabels();
  },
  methods: {
    getAllLabels() {
      this.$di.api.ContentStorage
        .courseItemLabels()
        .then((response) => {
          if (response && response.labels) {
            /**
             * Note: проставляем специально индекс потому что name и value поля могу дублироваться
             * WARNING: при отправке на бэкенд обязательно удалять эти индексы
             * */
            this.labelsList = response.labels.map((label, index) => ({ ...label, index }));
          } else {
            this.$di.notify.snackError('Не найдено меток');
          }
        })
        .catch(this.$di.notify.errorHandler);
    },
    getCourses() {
      const payload = {
        /** Note: убираем индекс, потому что он нужен только для правильной работы Select */
        labels: this.labelsSelected.map((label) => omit(label, 'index')),
        pagination: {
          limit: 100,
          offset: 0,
        },
      };

      this.$di.api.ContentStorage
        .courseItemIndex(payload)
        .then((response) => {
          if (response && response.courses) {
            /**
             * Note: проставляем специально индекс потому что name и value поля могу дублироваться
             * WARNING: при отправке на бэкенд обязательно удалять эти индексы
             * */
            this.coursesList = response.courses.map((course, index) => ({ ...course, index }));
          } else {
            this.$di.notify.snackError('Не найдено курсов');
          }
        })
        .catch(this.$di.notify.errorHandler);
    },
    labelsAutocompleteFilter(item, queryText) {
      /**
       * Note: эта проверка требуется, потому что метки не валидируются при создании
       * и в БД могут быть метки без этих полей
       * TODO: в будущем заменить проверку на оператор Optional chaining
       * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining
       */
      if (!item.value || !item.name) return false;

      const queryTextLowercase = queryText.toLocaleLowerCase();

      const hasQueryInName = item.name.toLocaleLowerCase().indexOf(queryTextLowercase) > -1;
      const hasQueryInValue = item.value.toLocaleLowerCase().indexOf(queryTextLowercase) > -1;

      return hasQueryInName || hasQueryInValue;
    },
  },
};
</script>
